import { faTachometerAlt } from '@fortawesome/pro-light-svg-icons/faTachometerAlt'
import { faTicketAlt } from '@fortawesome/pro-light-svg-icons/faTicketAlt'
import { faUserClock } from '@fortawesome/pro-light-svg-icons/faUserClock'
import { faUserPlus } from '@fortawesome/pro-light-svg-icons/faUserPlus'
import { faUserCheck } from '@fortawesome/pro-light-svg-icons/faUserCheck'
import { faUserCog } from '@fortawesome/pro-light-svg-icons/faUserCog'
import { faTable } from '@fortawesome/pro-light-svg-icons/faTable'
import { faBuilding } from '@fortawesome/pro-light-svg-icons/faBuilding'
import { faPaintBrush } from '@fortawesome/pro-light-svg-icons/faPaintBrush'
import { faLaptopCode } from '@fortawesome/pro-light-svg-icons/faLaptopCode'
import { faCode } from '@fortawesome/pro-light-svg-icons/faCode'
import { faCube } from '@fortawesome/pro-light-svg-icons/faCube'
import { faProjectDiagram } from '@fortawesome/pro-light-svg-icons/faProjectDiagram'
import { faHeadphonesAlt } from '@fortawesome/pro-light-svg-icons/faHeadphonesAlt'
import { faTireRugged } from '@fortawesome/pro-light-svg-icons/faTireRugged'
import { faBars } from '@fortawesome/pro-light-svg-icons/faBars'
import { faPlus } from '@fortawesome/pro-light-svg-icons/faPlus'
import { faMinus } from '@fortawesome/pro-light-svg-icons/faMinus'
import { faCog } from '@fortawesome/pro-light-svg-icons/faCog'
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons/faInfoCircle'
import { faInfo } from '@fortawesome/pro-light-svg-icons/faInfo'
import { faBell } from '@fortawesome/pro-light-svg-icons/faBell'
import { faBellSlash } from '@fortawesome/pro-light-svg-icons/faBellSlash'
import { faBriefcase } from '@fortawesome/pro-light-svg-icons/faBriefcase'
import { faLock } from '@fortawesome/pro-light-svg-icons/faLock'
import { faShieldAlt } from '@fortawesome/pro-light-svg-icons/faShieldAlt'
import { faUnlock } from '@fortawesome/pro-light-svg-icons/faUnlock'
import { faChevronDown } from '@fortawesome/pro-light-svg-icons/faChevronDown'
import { faChevronRight } from '@fortawesome/pro-light-svg-icons/faChevronRight'
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons/faChevronLeft'
import { faChevronUp } from '@fortawesome/pro-light-svg-icons/faChevronUp'
import { faDollarSign } from '@fortawesome/pro-light-svg-icons/faDollarSign'
import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons/faExclamationCircle'
import { faList } from '@fortawesome/pro-light-svg-icons/faList'
import { faListOl } from '@fortawesome/pro-light-svg-icons/faListOl'
import { faFileArchive } from '@fortawesome/pro-light-svg-icons/faFileArchive'
import { faFileAlt } from '@fortawesome/pro-light-svg-icons/faFileAlt'
import { faFileEdit } from '@fortawesome/pro-light-svg-icons/faFileEdit'
import { faThumbtack } from '@fortawesome/pro-light-svg-icons/faThumbtack'
import { faPencil } from '@fortawesome/pro-light-svg-icons/faPencil'
import { faUserEdit } from '@fortawesome/pro-light-svg-icons/faUserEdit'
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons/faTrashAlt'
import { faTrashUndoAlt } from '@fortawesome/pro-light-svg-icons/faTrashUndoAlt'
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes'
import { faClock } from '@fortawesome/pro-light-svg-icons/faClock'
import { faCommentLines } from '@fortawesome/pro-light-svg-icons/faCommentLines'
import { faBan } from '@fortawesome/pro-light-svg-icons/faBan'
import { faBandAid } from '@fortawesome/pro-light-svg-icons/faBandAid'
import { faAddressCard } from '@fortawesome/pro-light-svg-icons/faAddressCard'
import { faExternalLink } from '@fortawesome/pro-light-svg-icons/faExternalLink'
import { faQuestion } from '@fortawesome/pro-light-svg-icons/faQuestion'
import { faSearch } from '@fortawesome/pro-light-svg-icons/faSearch'
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons/faExclamationTriangle'
import { faClone } from '@fortawesome/pro-light-svg-icons/faClone'
import { faTimesCircle } from '@fortawesome/pro-light-svg-icons/faTimesCircle'
import { faTimesSquare } from '@fortawesome/pro-light-svg-icons/faTimesSquare'
import { faCheck } from '@fortawesome/pro-light-svg-icons/faCheck'
import { faPlusCircle } from '@fortawesome/pro-light-svg-icons/faPlusCircle'
import { faUpload } from '@fortawesome/pro-light-svg-icons/faUpload'
import { faCalendarAlt } from '@fortawesome/pro-light-svg-icons/faCalendarAlt'
import { faEllipsisV } from '@fortawesome/pro-light-svg-icons/faEllipsisV'
import { faTags } from '@fortawesome/pro-light-svg-icons/faTags'
import { faLightbulbOn } from '@fortawesome/pro-light-svg-icons/faLightbulbOn'
import { faLightbulbExclamation } from '@fortawesome/pro-light-svg-icons/faLightbulbExclamation'
import { faEye } from '@fortawesome/pro-light-svg-icons/faEye'
import { faEyeSlash } from '@fortawesome/pro-light-svg-icons/faEyeSlash'
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons/faArrowLeft'
import { faArrowRight } from '@fortawesome/pro-light-svg-icons/faArrowRight'
import { faLongArrowLeft } from '@fortawesome/pro-light-svg-icons/faLongArrowLeft'
import { faLongArrowRight } from '@fortawesome/pro-light-svg-icons/faLongArrowRight'
import { faFilter } from '@fortawesome/pro-light-svg-icons/faFilter'
import { faSortAmountUp } from '@fortawesome/pro-light-svg-icons/faSortAmountUp'
import { faSortAmountDown } from '@fortawesome/pro-light-svg-icons/faSortAmountDown'
import { faSort } from '@fortawesome/pro-light-svg-icons/faSort'
import { faSortUp } from '@fortawesome/pro-light-svg-icons/faSortUp'
import { faSortDown } from '@fortawesome/pro-light-svg-icons/faSortDown'
import { faHandshake } from '@fortawesome/pro-light-svg-icons/faHandshake'
import { faPuzzlePiece } from '@fortawesome/pro-light-svg-icons/faPuzzlePiece'
import { faReply } from '@fortawesome/pro-light-svg-icons/faReply'
import { faPaperPlane } from '@fortawesome/pro-light-svg-icons/faPaperPlane'
import { faPaperclip } from '@fortawesome/pro-light-svg-icons/faPaperclip'
import { faCommentCheck } from '@fortawesome/pro-light-svg-icons/faCommentCheck'
import { faWindowRestore } from '@fortawesome/pro-light-svg-icons/faWindowRestore'
import { faCheckCircle } from '@fortawesome/pro-light-svg-icons/faCheckCircle'
import { faFileCheck } from '@fortawesome/pro-light-svg-icons/faFileCheck'
import { faBusinessTime } from '@fortawesome/pro-light-svg-icons/faBusinessTime'
import { faCircle } from '@fortawesome/pro-light-svg-icons/faCircle'
import { faStar } from '@fortawesome/pro-light-svg-icons/faStar'
import { faEnvelope } from '@fortawesome/pro-light-svg-icons/faEnvelope'
import { faArrows } from '@fortawesome/pro-light-svg-icons/faArrows'
import { faMinusSquare } from '@fortawesome/pro-light-svg-icons/faMinusSquare'
import { faPlusSquare } from '@fortawesome/pro-light-svg-icons/faPlusSquare'
import { faSearchPlus } from '@fortawesome/pro-light-svg-icons/faSearchPlus'
import { faSearchMinus } from '@fortawesome/pro-light-svg-icons/faSearchMinus'
import { faSync } from '@fortawesome/pro-light-svg-icons/faSync'
import { faRedo } from '@fortawesome/pro-light-svg-icons/faRedo'
import { faUsers } from '@fortawesome/pro-light-svg-icons/faUsers'
import { faUser } from '@fortawesome/pro-light-svg-icons/faUser'
import { faStepForward } from '@fortawesome/pro-light-svg-icons/faStepForward'
import { faStepBackward } from '@fortawesome/pro-light-svg-icons/faStepBackward'
import { faLayerGroup } from '@fortawesome/pro-light-svg-icons/faLayerGroup'
import { faPrint } from '@fortawesome/pro-light-svg-icons/faPrint'
import { faUserSlash } from '@fortawesome/pro-light-svg-icons/faUserSlash'
import { faCommentAltLines } from '@fortawesome/pro-light-svg-icons/faCommentAltLines'
import { faUnlink } from '@fortawesome/pro-light-svg-icons/faUnlink'
import { faSpinnerThird } from '@fortawesome/pro-light-svg-icons/faSpinnerThird'
import { faLockAlt } from '@fortawesome/pro-light-svg-icons/faLockAlt'
import { faMap } from '@fortawesome/pro-light-svg-icons/faMap'
import { faDownload } from '@fortawesome/pro-light-svg-icons/faDownload'
import { faCommentSmile } from '@fortawesome/pro-light-svg-icons/faCommentSmile'
import { faTasks } from '@fortawesome/pro-light-svg-icons/faTasks'
import { faUnlockAlt } from '@fortawesome/pro-light-svg-icons/faUnlockAlt'
import { faFolder } from '@fortawesome/pro-light-svg-icons/faFolder'
import { faFolderOpen } from '@fortawesome/pro-light-svg-icons/faFolderOpen'
import { faThumbsUp } from '@fortawesome/pro-light-svg-icons/faThumbsUp'
import { faThumbsDown } from '@fortawesome/pro-light-svg-icons/faThumbsDown'
import { faBookOpen } from '@fortawesome/pro-light-svg-icons/faBookOpen'
import { faBallot } from '@fortawesome/pro-light-svg-icons/faBallot'
import { faShoppingCart } from '@fortawesome/pro-light-svg-icons/faShoppingCart'
import { faFileChartLine } from '@fortawesome/pro-light-svg-icons/faFileChartLine'
import { faComments } from '@fortawesome/pro-light-svg-icons/faComments'
import { faWindowClose } from '@fortawesome/pro-light-svg-icons/faWindowClose'
import { faCogs } from '@fortawesome/pro-light-svg-icons/faCogs'
import { faFileImport } from '@fortawesome/pro-light-svg-icons/faFileImport'
import { faMobileAndroid } from '@fortawesome/pro-light-svg-icons/faMobileAndroid'
import { faBullhorn } from '@fortawesome/pro-light-svg-icons/faBullhorn'
import { faKeyboard } from '@fortawesome/pro-light-svg-icons/faKeyboard'
import { faKey } from '@fortawesome/pro-light-svg-icons/faKey'
import { faGlobe } from '@fortawesome/pro-light-svg-icons/faGlobe'
import { faColumns } from '@fortawesome/pro-light-svg-icons/faColumns'
import { faFileExport } from '@fortawesome/pro-light-svg-icons/faFileExport'
import { faLaptop } from '@fortawesome/pro-light-svg-icons/faLaptop'
import { faDesktop } from '@fortawesome/pro-light-svg-icons/faDesktop'
import { faBarcode } from '@fortawesome/pro-light-svg-icons/faBarcode'
import { faQrcode } from '@fortawesome/pro-light-svg-icons/faQrcode'
import { faBarcodeScan } from '@fortawesome/pro-light-svg-icons/faBarcodeScan'
import { faHandHoldingBox } from '@fortawesome/pro-light-svg-icons/faHandHoldingBox'
import { faCopy } from '@fortawesome/pro-light-svg-icons/faCopy'
import { faStopCircle } from '@fortawesome/pro-light-svg-icons/faStopCircle'
import { faStop } from '@fortawesome/pro-light-svg-icons/faStop'
import { faPlay } from '@fortawesome/pro-light-svg-icons/faPlay'
import { faIdCard } from '@fortawesome/pro-light-svg-icons/faIdCard'
import { faExpandArrows } from '@fortawesome/pro-light-svg-icons/faExpandArrows'
import { faExpand } from '@fortawesome/pro-light-svg-icons/faExpand'
import { faBoxFull } from '@fortawesome/pro-light-svg-icons/faBoxFull'
import { faDotCircle } from '@fortawesome/pro-light-svg-icons/faDotCircle'
import { faMoneyBill } from '@fortawesome/pro-light-svg-icons/faMoneyBill'
import { faFileContract } from '@fortawesome/pro-light-svg-icons/faFileContract'
import { faClipboardListCheck } from '@fortawesome/pro-light-svg-icons/faClipboardListCheck'
import { faRocket } from '@fortawesome/pro-light-svg-icons/faRocket'
import { faLongArrowAltRight } from '@fortawesome/pro-light-svg-icons/faLongArrowAltRight'
import { faHistory } from '@fortawesome/pro-light-svg-icons/faHistory'
import { faBagsShopping } from '@fortawesome/pro-light-svg-icons/faBagsShopping'
import { faHandReceiving } from '@fortawesome/pro-light-svg-icons/faHandReceiving'
import { faStream } from '@fortawesome/pro-light-svg-icons/faStream'
import { faSortNumericUpAlt } from '@fortawesome/pro-light-svg-icons/faSortNumericUpAlt'
import { faExchange } from '@fortawesome/pro-light-svg-icons/faExchange'
import { faUserLock } from '@fortawesome/pro-light-svg-icons/faUserLock'
import { faPhoneAlt } from '@fortawesome/pro-light-svg-icons/faPhoneAlt'
import { faPhoneRotary } from '@fortawesome/pro-light-svg-icons/faPhoneRotary'
import { faPhonePlus } from '@fortawesome/pro-light-svg-icons/faPhonePlus'
import { faPhoneVolume } from '@fortawesome/pro-light-svg-icons/faPhoneVolume'
import { faPause } from '@fortawesome/pro-light-svg-icons/faPause'
import { faMicrophoneSlash } from '@fortawesome/pro-light-svg-icons/faMicrophoneSlash'
import { faMicrophone } from '@fortawesome/pro-light-svg-icons/faMicrophone'
import { faWindowMinimize } from '@fortawesome/pro-light-svg-icons/faWindowMinimize'
import { faWindowMaximize } from '@fortawesome/pro-light-svg-icons/faWindowMaximize'
import { faDiamond } from '@fortawesome/pro-light-svg-icons/faDiamond'
import { faHourglass } from '@fortawesome/pro-light-svg-icons/faHourglass'
import { faAlarmClock } from '@fortawesome/pro-light-svg-icons/faAlarmClock'
import { faBackspace } from '@fortawesome/pro-light-svg-icons/faBackspace'
import { faRetweet } from '@fortawesome/pro-light-svg-icons/faRetweet'
import { faMusic } from '@fortawesome/pro-light-svg-icons/faMusic'
import { faMusicSlash } from '@fortawesome/pro-light-svg-icons/faMusicSlash'
import { faVolume } from '@fortawesome/pro-light-svg-icons/faVolume'
import { faVolumeSlash } from '@fortawesome/pro-light-svg-icons/faVolumeSlash'
import { faBiohazard } from '@fortawesome/pro-light-svg-icons/faBiohazard'
import { faLanguage } from '@fortawesome/pro-light-svg-icons/faLanguage'
import { faSitemap } from '@fortawesome/pro-light-svg-icons/faSitemap'
import { faMapMarkerAlt } from '@fortawesome/pro-light-svg-icons/faMapMarkerAlt'
import { faPhone } from '@fortawesome/pro-light-svg-icons/faPhone'
import { faTasksAlt } from '@fortawesome/pro-light-svg-icons/faTasksAlt'
import { faFlag } from '@fortawesome/pro-light-svg-icons/faFlag'
import { faShare } from '@fortawesome/pro-light-svg-icons/faShare'
import { faSignOutAlt } from '@fortawesome/pro-light-svg-icons/faSignOutAlt'
import { faChevronDoubleRight } from '@fortawesome/pro-light-svg-icons/faChevronDoubleRight'
import { faCheckDouble } from '@fortawesome/pro-light-svg-icons/faCheckDouble'
import { faSlidersH } from '@fortawesome/pro-light-svg-icons/faSlidersH'
import { faEthernet } from '@fortawesome/pro-light-svg-icons/faEthernet'
import { faMedal } from '@fortawesome/pro-light-svg-icons/faMedal'

export default [
  faTachometerAlt,
  faTicketAlt,
  faUserClock,
  faUserPlus,
  faUserCog,
  faHistory,
  faBuilding,
  faPaintBrush,
  faLaptopCode,
  faCode,
  faCube,
  faProjectDiagram,
  faHeadphonesAlt,
  faTireRugged,
  faBars,
  faPlus,
  faMinus,
  faCog,
  faInfoCircle,
  faAddressCard,
  faInfo,
  faBell,
  faBellSlash,
  faLock,
  faShieldAlt,
  faBriefcase,
  faUnlock,
  faChevronDown,
  faChevronRight,
  faChevronLeft,
  faBiohazard,
  faExclamationCircle,
  faList,
  faListOl,
  faFileArchive,
  faFileAlt,
  faFileEdit,
  faThumbtack,
  faTrashAlt,
  faTrashUndoAlt,
  faPencil,
  faUserEdit,
  faTimes,
  faClock,
  faCommentLines,
  faBan,
  faBandAid,
  faExternalLink,
  faQuestion,
  faSearch,
  faExclamationTriangle,
  faClone,
  faTimesCircle,
  faCheck,
  faPlusCircle,
  faUpload,
  faCalendarAlt,
  faEllipsisV,
  faTags,
  faLightbulbOn,
  faLightbulbExclamation,
  faEye,
  faArrowLeft,
  faArrowRight,
  faLongArrowLeft,
  faLongArrowRight,
  faFilter,
  faSortAmountUp,
  faSortAmountDown,
  faSort,
  faSortUp,
  faSortDown,
  faHandshake,
  faPuzzlePiece,
  faReply,
  faPaperPlane,
  faPaperclip,
  faCommentCheck,
  faWindowRestore,
  faCheckCircle,
  faFileCheck,
  faBusinessTime,
  faCircle,
  faStar,
  faEnvelope,
  faArrows,
  faMinusSquare,
  faPlusSquare,
  faSearchPlus,
  faSearchMinus,
  faSync,
  faRedo,
  faTimesSquare,
  faChevronUp,
  faDollarSign,
  faUsers,
  faUser,
  faStepForward,
  faStepBackward,
  faLayerGroup,
  faPrint,
  faUserSlash,
  faCommentAltLines,
  faUnlink,
  faSpinnerThird,
  faEyeSlash,
  faLockAlt,
  faUserCheck,
  faMap,
  faDownload,
  faCommentSmile,
  faTasks,
  faUnlockAlt,
  faFolder,
  faFolderOpen,
  faThumbsUp,
  faThumbsDown,
  faBookOpen,
  faBallot,
  faTable,
  faShoppingCart,
  faFileChartLine,
  faComments,
  faWindowClose,
  faCogs,
  faFileImport,
  faMobileAndroid,
  faBullhorn,
  faKeyboard,
  faKey,
  faGlobe,
  faColumns,
  faFileExport,
  faLaptop,
  faDesktop,
  faBarcode,
  faQrcode,
  faBarcodeScan,
  faHandHoldingBox,
  faCopy,
  faStopCircle,
  faStop,
  faPlay,
  faIdCard,
  faExpandArrows,
  faExpand,
  faBoxFull,
  faDotCircle,
  faMoneyBill,
  faFileContract,
  faClipboardListCheck,
  faRocket,
  faLongArrowAltRight,
  faBagsShopping,
  faHandReceiving,
  faStream,
  faSortNumericUpAlt,
  faExchange,
  faUserLock,
  faPhoneAlt,
  faPhoneRotary,
  faPhonePlus,
  faPhoneVolume,
  faPause,
  faMicrophoneSlash,
  faMicrophone,
  faWindowMinimize,
  faWindowMaximize,
  faDiamond,
  faHourglass,
  faAlarmClock,
  faBackspace,
  faRetweet,
  faMusic,
  faMusicSlash,
  faVolume,
  faVolumeSlash,
  faLanguage,
  faSitemap,
  faMapMarkerAlt,
  faPhone,
  faTasksAlt,
  faFlag,
  faShare,
  faSignOutAlt,
  faChevronDoubleRight,
  faCheckDouble,
  faSlidersH,
  faEthernet,
  faMedal,
]
